import React, { useMemo } from 'react';
import Button from '../../../modules/flows/presentational/Button';
import styled from 'styled-components';
import { Modal, Tabs, Typography } from 'antd';
import { colors } from '../../../constants';
import { usePipeline } from '../../../uni-comms-api/hooks/usePipeline';
import { useSelectedClient } from '../../../uni-comms-api/hooks/authentication';
import { useModalManager } from '../ModalManager';

import aiAssistantLogo from '../../assets/ai-assistant-logo.svg';
import squarespaceLogo from '../../assets/squarespace-logo.png';
import wordpressLogo from '../../assets/wordpress-logo.png';
import shopifyLogo from '../../assets/shopify-logo.png';
import gtmLogo from '../../assets/google-tag-manager-logo.svg';

import StepBuilder from './StepBuilder';
import CodeSnippet from './CodeSnippet';
import PluginBlock from './PluginBlock';
import EmailBlock from './EmailBlock';
import {
  COPY_SNIPPET_TEXT,
  HTML_WITH_GTM_STEPS,
  SHOPIFY_STEPS,
  SQUARESPACE_STEPS,
  WORDPRESS_PLUGIN,
  WORDPRESS_STEPS
} from './steps';

export interface LaunchAIProps {
  label?: string;
  studioEvent?: () => void;
}

const LaunchMethods = {
  AI_ASSISTANT_CODE: 'AI assistant code',
  SQUARESPACE_SITE: 'Squarespace site',
  WORDPRESS_SITE: 'Wordpress site',
  SHOPIFY_SITE: 'Shopify site',
  HTML_WITH_GTM: 'HTML website with Google Tag Manager'
};

const LaunchAI: React.FC<LaunchAIProps> = ({
  label = 'Launch your AI',
  studioEvent
}) => {
  const modalId = 'launch-ai';
  const { openModal, closeModal, isActiveModal } = useModalManager();
  const { selectedClient } = useSelectedClient();
  const { pipeline } = usePipeline();

  const codeConfig = useMemo(
    () => ({
      clientId: selectedClient,
      pipelineId: pipeline._id?.toString() || ''
    }),
    [selectedClient, pipeline]
  );

  const launchAiMethods = [
    {
      key: LaunchMethods.AI_ASSISTANT_CODE,
      label: (
        <Label>
          <img src={aiAssistantLogo} alt="" /> {LaunchMethods.AI_ASSISTANT_CODE}
        </Label>
      ),
      children: (
        <>
          <StepBuilder
            steps={[
              {
                content: <CodeSnippet {...codeConfig} />
              }
            ]}
          />
          <OrDivider />
          <EmailBlock {...codeConfig} />
        </>
      )
    },
    {
      key: LaunchMethods.SQUARESPACE_SITE,
      label: (
        <Label>
          <img src={squarespaceLogo} alt="" /> {LaunchMethods.SQUARESPACE_SITE}
        </Label>
      ),
      children: (
        <>
          <StepBuilder
            steps={[
              {
                header: COPY_SNIPPET_TEXT,
                content: (
                  <>
                    Copy the code snippet to your clipboard
                    <CodeSnippet {...codeConfig} />
                  </>
                )
              },
              ...SQUARESPACE_STEPS
            ]}
          />
          <OrDivider />
          <EmailBlock {...codeConfig} />
        </>
      )
    },
    {
      key: LaunchMethods.WORDPRESS_SITE,
      label: (
        <Label>
          <img src={wordpressLogo} alt="" /> {LaunchMethods.WORDPRESS_SITE}
        </Label>
      ),
      children: (
        <>
          <PluginBlock {...WORDPRESS_PLUGIN} />
          <OrDivider />
          <StepBuilder
            steps={[
              {
                header: COPY_SNIPPET_TEXT,
                content: (
                  <>
                    {COPY_SNIPPET_TEXT} <CodeSnippet {...codeConfig} />
                  </>
                )
              },
              ...WORDPRESS_STEPS
            ]}
          />
          <OrDivider />
          <EmailBlock {...codeConfig} />
        </>
      )
    },
    {
      key: LaunchMethods.SHOPIFY_SITE,
      label: (
        <Label>
          <img src={shopifyLogo} alt="" /> {LaunchMethods.SHOPIFY_SITE}
        </Label>
      ),
      children: (
        <>
          <StepBuilder
            steps={[
              {
                header: COPY_SNIPPET_TEXT,
                content: (
                  <>
                    {COPY_SNIPPET_TEXT} <CodeSnippet {...codeConfig} />
                  </>
                )
              },
              ...SHOPIFY_STEPS
            ]}
          />
          <OrDivider />
          <EmailBlock {...codeConfig} />
        </>
      )
    },
    {
      key: LaunchMethods.HTML_WITH_GTM,
      label: (
        <Label>
          <img src={gtmLogo} alt="" /> {LaunchMethods.HTML_WITH_GTM}
        </Label>
      ),
      children: (
        <>
          <StepBuilder
            steps={[
              {
                header: COPY_SNIPPET_TEXT,
                content: (
                  <>
                    {COPY_SNIPPET_TEXT} <CodeSnippet {...codeConfig} />
                  </>
                )
              },
              ...HTML_WITH_GTM_STEPS
            ]}
          />
          <OrDivider />
          <EmailBlock {...codeConfig} />
        </>
      )
    }
  ];

  return (
    <>
      <Button
        onClick={() => {
          openModal(modalId);
          studioEvent && studioEvent();
        }}
      >
        {label}
      </Button>
      <StyledModal
        open={isActiveModal(modalId)}
        onCancel={() => closeModal()}
        closable
        width={844}
        footer={null}
        style={{ top: 20 }}
      >
        <HeaderContainer>
          <Heading level={4}>Code for your AI assistant</Heading>
          <SubHeader>
            This is the exciting part… making your AI assistant go live.
            <br /> <br />
            Copy and paste this code snippet into the HTML code of the page you
            want your assistant to appear on.
          </SubHeader>
        </HeaderContainer>

        <MethodTabs
          defaultActiveKey={LaunchMethods.AI_ASSISTANT_CODE}
          tabPosition="left"
          items={launchAiMethods}
        ></MethodTabs>
      </StyledModal>
    </>
  );
};

const OrDivider = () => (
  <OrContainer>
    Or
    <span className="line" />
  </OrContainer>
);

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 20px 30px 20px 0px;
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  margin: 0px 30px 30px;
`;

const Heading = styled(Typography.Title)`
  color: ${colors.DARK_BLUE};
`;

const SubHeader = styled(Typography.Paragraph)`
  color: ${colors.DARK_BLUE};
  font-size: 16px;
  margin-bottom: 0px;
  line-height: 18px;
`;

const MethodTabs = styled(Tabs)`
  .ant-tabs-nav-wrap {
    width: 250px !important;
  }

  .ant-tabs-tab {
    margin: 0px !important;
    padding: 12px 30px !important;
  }

  .ant-tabs-tab-active {
    background-color: ${colors.RED}0D !important;
  }
`;

const Label = styled.span`
  display: inline-flex;
  gap: 8px;
  width: 180px;
  text-wrap: wrap;
  text-align: left;

  > img {
    height: 20px;
  }
`;

const OrContainer = styled.div`
  font-style: italic;
  color: ${colors.DARK_BLUE};
  font-size: 16px;
  margin: 20px 0px;
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: bold;
  gap: 6px;

  .line {
    height: 1px;
    width: 100%;
    background-color: #06233d52;
  }
`;

export default LaunchAI;
